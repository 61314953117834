<template>
	<Dialog :dialog="personDialog" :dialogWidth="1000">
		<template v-slot:title>{{ personTitle }}</template>
		<template v-slot:body>
			<div class="">
				<v-form
					v-if="personDialog"
					ref="personForm"
					v-model.trim="personFormValid"
					lazy-validation
					v-on:submit.stop.prevent="updatePerson"
				>
					<v-row>
						<v-col md="4" class="pt-0">
							<label for="person-salutation" class="btx-label mt-2">Salutation</label>
							<SelectInput
								:items.sync="listTitle"
								id="person-salutation"
								hide-details
								placeholder="Salutation"
								v-model="personModel.salutation"
								class="mt-0"
							></SelectInput>
						</v-col>
						<v-col md="4" class="pt-0">
							<label for="person-first-name" class="btx-label mt-2 required">First Name</label>
							<TextInput
								id="person-first-name"
								v-model="personModel.first_name"
								hide-details
								placeholder="First Name"
								:rules="[vrules.required(personModel.first_name, 'First Name')]"
								v-on:keyup="updatePersonDisplayName()"
								class="mt-0"
								:class="{
									required: !personModel.first_name,
								}"
							></TextInput>
						</v-col>
						<v-col md="4" class="pt-0">
							<label for="person-last-name" class="btx-label mt-2">Last Name</label>
							<TextInput
								id="person-last-name"
								v-model="personModel.last_name"
								hide-details
								placeholder="Last Name"
								v-on:keyup="updatePersonDisplayName()"
								class="mt-0"
							></TextInput>
						</v-col>
						<v-col md="4">
							<label for="person-display-name" class="btx-label mt-2 required">Display Name</label>
							<TextInput
								v-if="false"
								id="person-display-name"
								v-model="personModel.display_name"
								hide-details
								placeholder="Display Name"
								class="mt-0"
								:class="{
									required: !personModel.display_name,
								}"
								:rules="[vrules.required(personModel.display_name, 'Display Name')]"
							></TextInput>
							<TextValidateInput
								hide-details
								hide-top-margin
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Display Name"
								id="person-display-name"
								:rules="[
									vrules.required(personModel.display_name, 'Display Name'),
									vrules.minLength(personModel.display_name, 'display name', 2),
									vrules.maxLength(personModel.display_name, 'display name', 100),
								]"
								:class="{
									required: !personModel.display_name,
								}"
								:validationField="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'display_name',
								}"
								:parent-id="person && !is_empty(person) ? contactId : null"
								:current-id="personModel.id"
								show-dropdown
								v-model="personModel.display_name"
							></TextValidateInput>
						</v-col>
						<v-col md="4">
							<label for="person-mobile" class="btx-label mt-2 required">Mobile</label>
							<PhoneInput
								required
								id="person-mobile"
								v-model="personModel.mobile"
								hide-details
								hide-top-margin
								placeholder="Mobile"
								class-name="mt-0"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'phone_number',
								}"
								:parent-id="person && !is_empty(person) ? contactId : null"
								:current-id="personModel.id"
							></PhoneInput>
						</v-col>
						<v-col md="4">
							<label for="DID-mobile" class="btx-label mt-2">DID</label>
							<PhoneInput
								id="DID-mobile"
								v-model="personModel.did"
								hide-details
								hide-top-margin
								placeholder="DID"
								class-name="mt-0"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'did',
								}"
								:parent-id="person && !is_empty(person) ? contactId : null"
								:current-id="personModel.id"
							></PhoneInput>
						</v-col>
						<v-col md="4">
							<label for="person-email" class="btx-label mt-2 required">Email Address</label>
							<EmailInput
								id="person-email"
								v-model="personModel.email"
								hide-details
								hide-top-margin
								placeholder="Email Address"
								:rules="[vrules.required(personModel.email, 'Email Address')]"
								class="mt-0"
								:class="{
									required: !personModel.email,
								}"
								:validation-field="{
									url_type: type,
									filter_type: 'contact_person',
									field: 'email',
								}"
								:parent-id="person && !is_empty(person) ? contactId : null"
								:current-id="personModel.id"
							></EmailInput>
						</v-col>
						<v-col md="4">
							<label for="person-mobile" class="btx-label mt-2">Designation</label>
							<AutoCompleteInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="designation"
								placeholder="Designation"
								v-model="personModel.designation"
								:items="designationList"
								append-outer-icon="mdi-cog"
								v-on:click:append-outer="manageDesignationDialog = true"
								class="mt-0"
							></AutoCompleteInput>
						</v-col>
						<v-col md="12" v-if="false">
							<v-checkbox
								dense
								color="blue darken-4"
								v-model="personModel.primary"
								:false-value="0"
								:true-value="1"
								hide-details
								class="m-0 p-0"
								label="Set As Primary"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-form>
				<template v-if="manageDesignationDialog">
					<ManageDesignation
						:dialog="manageDesignationDialog"
						:list="designationList"
						v-on:close="manageDesignationDialog = false"
						v-on:success="getOption('designation')"
					></ManageDesignation>
				</template>
			</div>
		</template>
		<template v-slot:action>
			<v-btn :disabled="personFormLoading" depressed tile v-on:click="$emit('close', true)">
				Close
			</v-btn>
			<v-btn
				class="white--text"
				depressed
				:disabled="!personFormValid || personFormLoading"
				:loading="personFormLoading"
				color="blue darken-4"
				@click="updatePerson()"
				tile
			>
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import ManageDesignation from "@/view/components/Manage-Designation.vue";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import PhoneInput from "@/view/components/PhoneInput";
import TextValidateInput from "@/view/components/TextValidateInput";
import { CreateOrUpdateContactPerson, ValidateContact } from "@/core/lib/contact.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "create-update-person",
	title: "Create Update Person",
	props: {
		personDialog: {
			type: Boolean,
			default: false,
		},
		contactUuid: {
			type: String,
			default: null,
		},
		contactId: {
			type: [Number, String],
			default: null,
		},
		person: {
			type: Object,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			personFormValid: true,
			personFormLoading: false,
			manageDesignationDialog: false,
			pageLoading: false,
			designationList: [],
			personTitle: "Create Contact Person",
			personModel: {
				id: null,
				uuid: null,
				primary: false,
				salutation: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				designation: null,
				mobile: null,
				did: null,
			},
		};
	},
	watch: {
		person: {
			deep: true,
			handler() {
				this.updatePersonDialog();
			},
		},
	},
	methods: {
		getOption() {
			this.$store.dispatch(SET_LOCAL_DB).then(() => {
				this.initialize();
			});
		},
		initialize() {
			this.designationList = this.localDB("designation", []);
		},
		updatePersonDisplayName() {
			const personModel = this.personModel;
			const display_name = [];
			if (personModel) {
				if (personModel.first_name) {
					display_name.push(personModel.first_name);
				}
				if (personModel.last_name) {
					display_name.push(personModel.last_name);
				}
				this.personModel.display_name = display_name.join(" ");
			}
		},
		is_empty(data) {
			return isEmpty(data);
		},
		updatePersonDialog() {
			if (this.person && !isEmpty(this.person)) {
				this.personTitle = "Update Contact Person";
				this.personModel = new Object({
					id: this.person.id || null,
					uuid: this.person.uuid || null,
					salutation: this.person.salutation || "mr",
					first_name: this.person.first_name || null,
					last_name: this.person.last_name || null,
					email: this.person.email || null,
					mobile: this.person.mobile || null,
					did: this.person.did || null,
					display_name: this.person.display_name || null,
					designation: this.person.designation || null,
					primary: this.person.primary || null,
				});
			} else {
				this.personTitle = "Create Contact Person";
				this.personModel = new Object({
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					email: null,
					mobile: null,
					did: null,
				});
			}
		},
		async updatePerson() {
			const _this = this;
			if (!this.$refs.personForm.validate()) {
				return false;
			}
			/* backend validation */
			const _person = [
				{
					id: _this.personModel.id,
					fields: [
						{
							field: "display_name",
							value: _this.personModel.display_name,
						},
						{
							field: "phone_number",
							value: _this.personModel.mobile,
						},
						{
							field: "email",
							value: _this.personModel.email,
						},
						{
							field: "did",
							value: _this.personModel.did,
						},
					],
				},
			];
			const validatePayload = {
				id: this.newAddress ? null : this.contactId,
				filter_type: "contact_person",
				fields: null,
				data: _person,
			};
			const { exists, error_message } = await ValidateContact(this.type, validatePayload);
			if (exists) {
				if (error_message) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
				}
				return false;
			}
			/* backend validation end  */
			try {
				this.personFormLoading = true;
				await CreateOrUpdateContactPerson(this.type, this.contactUuid, {
					persons: [this.personModel],
				});
				this.$emit("success", true);
				this.$emit("close", true);
				this.$store.commit(SET_MESSAGE, [
					{
						model: true,
						message: "Success ! Contact person's information has been saved.",
					},
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.$emit("error", true);
			} finally {
				this.personFormLoading = false;
			}
		},
	},
	components: {
		Dialog,
		TextInput,
		EmailInput,
		SelectInput,
		PhoneInput,
		ManageDesignation,
		AutoCompleteInput,
		TextValidateInput,
	},
	mounted() {
		this.updatePersonDialog();
		this.initialize();
	},
	computed: {
		...mapGetters(["localDB"]),
	},
};
</script>
