var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"dialog":_vm.personDialog,"dialogWidth":1000},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.personTitle))]},proxy:true},{key:"body",fn:function(){return [_c('div',{},[(_vm.personDialog)?_c('v-form',{ref:"personForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updatePerson.apply(null, arguments)}},model:{value:(_vm.personFormValid),callback:function ($$v) {_vm.personFormValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"personFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2",attrs:{"for":"person-salutation"}},[_vm._v("Salutation")]),_c('SelectInput',{staticClass:"mt-0",attrs:{"items":_vm.listTitle,"id":"person-salutation","hide-details":"","placeholder":"Salutation"},on:{"update:items":function($event){_vm.listTitle=$event}},model:{value:(_vm.personModel.salutation),callback:function ($$v) {_vm.$set(_vm.personModel, "salutation", $$v)},expression:"personModel.salutation"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"person-first-name"}},[_vm._v("First Name")]),_c('TextInput',{staticClass:"mt-0",class:{
								required: !_vm.personModel.first_name,
							},attrs:{"id":"person-first-name","hide-details":"","placeholder":"First Name","rules":[_vm.vrules.required(_vm.personModel.first_name, 'First Name')]},on:{"keyup":function($event){return _vm.updatePersonDisplayName()}},model:{value:(_vm.personModel.first_name),callback:function ($$v) {_vm.$set(_vm.personModel, "first_name", $$v)},expression:"personModel.first_name"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2",attrs:{"for":"person-last-name"}},[_vm._v("Last Name")]),_c('TextInput',{staticClass:"mt-0",attrs:{"id":"person-last-name","hide-details":"","placeholder":"Last Name"},on:{"keyup":function($event){return _vm.updatePersonDisplayName()}},model:{value:(_vm.personModel.last_name),callback:function ($$v) {_vm.$set(_vm.personModel, "last_name", $$v)},expression:"personModel.last_name"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"person-display-name"}},[_vm._v("Display Name")]),(false)?_c('TextInput',{staticClass:"mt-0",class:{
								required: !_vm.personModel.display_name,
							},attrs:{"id":"person-display-name","hide-details":"","placeholder":"Display Name","rules":[_vm.vrules.required(_vm.personModel.display_name, 'Display Name')]},model:{value:(_vm.personModel.display_name),callback:function ($$v) {_vm.$set(_vm.personModel, "display_name", $$v)},expression:"personModel.display_name"}}):_vm._e(),_c('TextValidateInput',{class:{
								required: !_vm.personModel.display_name,
							},attrs:{"hide-details":"","hide-top-margin":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"placeholder":"Display Name","id":"person-display-name","rules":[
								_vm.vrules.required(_vm.personModel.display_name, 'Display Name'),
								_vm.vrules.minLength(_vm.personModel.display_name, 'display name', 2),
								_vm.vrules.maxLength(_vm.personModel.display_name, 'display name', 100),
							],"validationField":{
								url_type: _vm.type,
								filter_type: 'contact_person',
								field: 'display_name',
							},"parent-id":_vm.person && !_vm.is_empty(_vm.person) ? _vm.contactId : null,"current-id":_vm.personModel.id,"show-dropdown":""},model:{value:(_vm.personModel.display_name),callback:function ($$v) {_vm.$set(_vm.personModel, "display_name", $$v)},expression:"personModel.display_name"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"person-mobile"}},[_vm._v("Mobile")]),_c('PhoneInput',{attrs:{"required":"","id":"person-mobile","hide-details":"","hide-top-margin":"","placeholder":"Mobile","class-name":"mt-0","validation-field":{
								url_type: _vm.type,
								filter_type: 'contact_person',
								field: 'phone_number',
							},"parent-id":_vm.person && !_vm.is_empty(_vm.person) ? _vm.contactId : null,"current-id":_vm.personModel.id},model:{value:(_vm.personModel.mobile),callback:function ($$v) {_vm.$set(_vm.personModel, "mobile", $$v)},expression:"personModel.mobile"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2",attrs:{"for":"DID-mobile"}},[_vm._v("DID")]),_c('PhoneInput',{attrs:{"id":"DID-mobile","hide-details":"","hide-top-margin":"","placeholder":"DID","class-name":"mt-0","validation-field":{
								url_type: _vm.type,
								filter_type: 'contact_person',
								field: 'did',
							},"parent-id":_vm.person && !_vm.is_empty(_vm.person) ? _vm.contactId : null,"current-id":_vm.personModel.id},model:{value:(_vm.personModel.did),callback:function ($$v) {_vm.$set(_vm.personModel, "did", $$v)},expression:"personModel.did"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2 required",attrs:{"for":"person-email"}},[_vm._v("Email Address")]),_c('EmailInput',{staticClass:"mt-0",class:{
								required: !_vm.personModel.email,
							},attrs:{"id":"person-email","hide-details":"","hide-top-margin":"","placeholder":"Email Address","rules":[_vm.vrules.required(_vm.personModel.email, 'Email Address')],"validation-field":{
								url_type: _vm.type,
								filter_type: 'contact_person',
								field: 'email',
							},"parent-id":_vm.person && !_vm.is_empty(_vm.person) ? _vm.contactId : null,"current-id":_vm.personModel.id},model:{value:(_vm.personModel.email),callback:function ($$v) {_vm.$set(_vm.personModel, "email", $$v)},expression:"personModel.email"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label mt-2",attrs:{"for":"person-mobile"}},[_vm._v("Designation")]),_c('AutoCompleteInput',{staticClass:"mt-0",attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"designation","placeholder":"Designation","items":_vm.designationList,"append-outer-icon":"mdi-cog"},on:{"click:append-outer":function($event){_vm.manageDesignationDialog = true}},model:{value:(_vm.personModel.designation),callback:function ($$v) {_vm.$set(_vm.personModel, "designation", $$v)},expression:"personModel.designation"}})],1),(false)?_c('v-col',{attrs:{"md":"12"}},[_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"dense":"","color":"blue darken-4","false-value":0,"true-value":1,"hide-details":"","label":"Set As Primary"},model:{value:(_vm.personModel.primary),callback:function ($$v) {_vm.$set(_vm.personModel, "primary", $$v)},expression:"personModel.primary"}})],1):_vm._e()],1)],1):_vm._e(),(_vm.manageDesignationDialog)?[_c('ManageDesignation',{attrs:{"dialog":_vm.manageDesignationDialog,"list":_vm.designationList},on:{"close":function($event){_vm.manageDesignationDialog = false},"success":function($event){return _vm.getOption('designation')}}})]:_vm._e()],2)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.personFormLoading,"depressed":"","tile":""},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","disabled":!_vm.personFormValid || _vm.personFormLoading,"loading":_vm.personFormLoading,"color":"blue darken-4","tile":""},on:{"click":function($event){return _vm.updatePerson()}}},[_vm._v(" Save ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }